import { faSquare } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";


function Supplychain() {
  return (
    <div>
      <div className="deft-desktop-supplychain"></div>
      <div class="twelve">
        <h1
          class="it-consulting-heading"
          data-aos="zoom-in-down"
          style={{ fontFamily: "poppins" }}
        >
          SUPPLY CHAIN
        </h1>
      </div>
      <div className="deft-desktop-supplychain-sub"></div>
      <section class="about-section">
        <div class="container">
          <div class="row clearfix">
            {/* Image Column */}
            <div class="image-column col-md-6 col-sm-12 col-xs-12">
              <div class="inner-column " data-wow-delay="0ms" data-wow-duration="1500ms">
                <div class="image-deft-supplychain">
                  <img src="../../Assets/supplychainimg.webp" alt="" />
                </div>
              </div>
            </div>

            {/* Content Column */}
            <div class="content-column col-md-6 col-sm-12 col-xs-12">
              <div class="inner-column">
                <div class="sec-title"></div>
                <div class="text">
                  Supply Chain Solutions: Optimizing Efficiency with Cutting-Edge Technology In today’s fast-paced global market,
                  an efficient supply chain is crucial for business success. Our IT consulting services offer advanced solutions
                  that help businesses optimize their supply chain operations, improve visibility, and streamline processes.
                  From inventory management to logistics, we provide end-to-end solutions that enhance the flow of goods and
                  information across the entire supply chain.
                </div>
                <div class="text">
                  We focus on implementing technology that enables real-time tracking, predictive analytics, and automation,
                  ensuring that every part of the supply chain is managed efficiently. <br />
                  <b>Our expertise spans:</b>
                </div>
                {/* Expertise List */}
                <div className="d-flex textAlign-justify">
                  <span>
                    <FontAwesomeIcon icon={faSquare} />{" "}
                    <b className="text-underline">Inventory Optimization</b> :{" "}
                    <span>Using data-driven insights to manage stock levels and reduce waste.</span>{" "}
                  </span>
                </div>
                <div className="d-flex textAlign-justify">
                  <span>
                    <FontAwesomeIcon icon={faSquare} />{" "}
                    <b className="text-underline">Demand Forecasting </b> :{" "}
                    <span>
                      Leveraging AI and machine learning for accurate demand predictions, minimizing stockouts and
                      overstocking.
                    </span>{" "}
                  </span>
                </div>
                <div className="d-flex textAlign-justify">
                  <span>
                    <FontAwesomeIcon icon={faSquare} />{" "}
                    <b className="text-underline">Logistics Management </b> :{" "}
                    <span>
                      Enhancing transportation efficiency and route optimization to reduce costs and improve delivery
                      times.
                    </span>{" "}
                  </span>
                </div>
                <div className="d-flex textAlign-justify">
                  <span>
                    <FontAwesomeIcon icon={faSquare} />{" "}
                    <b className="text-underline">Supply Chain Visibility </b> :{" "}
                    <span>
                      Offering real-time data tracking and reporting to improve decision-making and responsiveness.
                    </span>{" "}
                  </span>
                </div>
                <div className="d-flex textAlign-justify">
                  <span>
                    <FontAwesomeIcon icon={faSquare} />{" "}
                    <b className="text-underline">Automation </b> :{" "}
                    <span>
                      Streamlining manual processes to reduce errors, increase speed, and lower operational costs.
                    </span>{" "}
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      
      <div className="deft-desktop-supplychain"></div>

      {/* Second Section */}
      <section class="about-section">
        <div class="container">
          <div class="row clearfix">
            {/* Content Column */}
            <div class="content-column col-md-6 col-sm-12 col-xs-12 order-md-1 order-2">
              <div class="inner-column" style={{ marginTop: "40px" }}>
                <div class="sec-title"></div>
                <div class="text">
                  With our expertise, we integrate advanced technologies like artificial intelligence, machine learning, to
                  provide real-time analytics, demand forecasting, and end-to-end supply chain visibility. Whether it’s inventory
                  management, procurement, logistics, or order fulfillment, our solutions are tailored to address your unique
                  challenges and scale with your growth.
                </div>
                <div class="rounded p-3" style={{ boxShadow: "5px 5px 10px green", fontSize: "14px", fontWeight: "bold", margin: "20px auto", marginLeft: "-10px" }}>
                  "Our team collaborates closely with you to identify inefficiencies, design strategies, and implement software
                  systems that transform your supply chain into a competitive advantage. We ensure seamless connectivity across your
                  supply chain network, enabling faster decision-making and better customer satisfaction."
                </div>
                <div class="text">
                  By choosing our IT consulting services, you gain a partner committed to driving operational excellence and delivering
                  measurable results. Let us help you reimagine your supply chain with innovation and precision, so you can focus on
                  achieving your strategic goals and building a resilient future for your business.
                </div>
              </div>
            </div>

            {/* Image Col */}
            <div class="image-column col-md-6 col-sm-12 col-xs-12 order-md-2 order-1">
              <div class="inner-column" data-wow-delay="0ms" data-wow-duration="1500ms">
                <div class="image-deft-supplychain">
                  <img src="../../Assets/supplychainimg.webp" alt="" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default Supplychain;
