import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import NavDropdown from "react-bootstrap/NavDropdown";
import React, { useRef,useState } from "react";
import { useNavigate } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars } from "@fortawesome/free-solid-svg-icons";
function BasicExample() {
  const itSolutionsRef = useRef(null);

  const scrollToSection = (ref) => {
    ref.current.scrollIntoView({ behavior: "smooth" });
  };
  const navigate = useNavigate();
  const handlleITCosulting = () => {
    navigate("/itconsulting");
  };
  const handleAISolutions = () => {
    navigate("/aisolutions");
  };
  const handleMicroservices = () => {
    navigate("/microservices");
  };
  const handleAboutus = () => {
    navigate("/aboutus");
  };
  const handleContactus = () => {
    navigate("/contactus");
  };
  const handleFintech = () => {
    navigate("/fintech");
  };
  const handleSupplychain = () => {
    navigate("/supplychain");
  };

  const [isNavbarOpen, setNavbarOpen] = useState(false);

  const handleNavbarToggle = () => {
    setNavbarOpen(!isNavbarOpen); // Toggle the navbar
  };

  // Function to close the navbar when a dropdown item is clicked
  const handleNavItemClick = () => {
    setNavbarOpen(false); // Close the navbar
  };
  
  return (
    <Navbar expand="xl" className="bg-body-tertiary" fixed="top">
      <Container>
        <Navbar.Brand href="/">
          {" "}
          <img
            src="../../Assets/deft-3.png"
            style={{
              height: "30px",
              width: "auto",
            }}
            alt="DEFT LOGO"
          />
          Deft Lab
        </Navbar.Brand>
        <Navbar.Toggle
        aria-controls="basic-navbar-nav"
        style={{ backgroundColor: "white" }}
        onClick={handleNavbarToggle} // Toggle navbar visibility on click
      >
        <FontAwesomeIcon icon={faBars} />
      </Navbar.Toggle>
      <Navbar.Collapse id="basic-navbar-nav" in={isNavbarOpen}>
        <Nav className="ms-auto">
          <Nav.Link href="/" className="text-black" onClick={handleNavItemClick}>
            Home
          </Nav.Link>

          {/* Digital Transformation Dropdown */}
          <NavDropdown
            title={<span style={{ color: "black" }}>Digital Transformation</span>}
            id="digital-transformation-dropdown"
          >
            <NavDropdown.Item
              onClick={() => {
                handleNavItemClick();
                handlleITCosulting();
              }}
              className="navs-hover text-black"
            >
              IT Consulting
            </NavDropdown.Item>
            <NavDropdown.Item
              onClick={() => {
                handleNavItemClick();
                handleAISolutions();
              }}
              className="navs-hover text-black"
            >
              AI Solutions
            </NavDropdown.Item>
            <NavDropdown.Item
              onClick={() => {
                handleNavItemClick();
                handleMicroservices();
              }}
              className="navs-hover text-black"
            >
              Micro Services
            </NavDropdown.Item>
          </NavDropdown>

          {/* Solutions Dropdown */}
          <NavDropdown
            title={<span style={{ color: "black" }}>Solutions</span>}
            id="solutions-dropdown"
          >
            <NavDropdown.Item
              onClick={() => {
                handleNavItemClick();
                handleSupplychain();
              }}
              className="navs-hover text-black"
            >
              Supply Chain
            </NavDropdown.Item>
            <NavDropdown.Item
              onClick={() => {
                handleNavItemClick();
                handleFintech();
              }}
              className="navs-hover text-black"
            >
              Fintech
            </NavDropdown.Item>
          </NavDropdown>

          {/* Other Navbar Links */}
          <Nav.Link onClick={() => { handleNavItemClick(); handleAboutus(); }} className="text-black">
            About Us
          </Nav.Link>
          <Nav.Link onClick={() => { handleNavItemClick(); handleContactus(); }} className="text-black">
            Contact Us
          </Nav.Link>
        </Nav>
      </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}

export default BasicExample;
