import React, { useState, useEffect } from "react";

import Footer from "../components/Footer";
import CommonNavbar from "../CommonNavbar/Commonnavbar";
// import "../components/Responsive.css";
import Aos from "aos";
import "aos/dist/aos.css";

function Microservices() {
  useEffect(() => {
    Aos.init({ duration: 2000 });
  }, []);
  return (
    <div>
      <CommonNavbar />
      <div className="mob-view-starting"></div>
      <div class="twelve">
        <h1 class="it-consulting-heading" data-aos="zoom-in-down">
          MICRO SERVICES
        </h1>
      </div>
      {/* <div  className='mob-view'></div> */}
      <div class="div1" data-aos="zoom-in-up">
        <div class="pricing-container">
          <div class="pricing-card" onclick="selectCard(this)">
            <h3>Microservice Architecture Services</h3>
            <p class="price">
              Helps improve system flexibility by allowing the creation and
              expansion of components in a non-intrusive way
            </p>
            {/* <ul>
        <li>Introduction to Swift</li>
        <li>Basic App Development</li>
        <li>3-Month Access</li>
      </ul> */}
            {/* <button class="btn">Choose iOS Basics</button> */}
          </div>

          <div
            class="pricing-card "
            //  featured onclick=selectCard(this)
          >
            <h3>Containerized and Migration-Focused Architectures</h3>
            <p class="price">
              {" "}
              We provide containerized, migration-oriented architectures
              designed for enhanced flexibility, fault tolerance, and seamless
              adaptation to changing business needs.{" "}
            </p>

            {/* <button class="btn">Choose Advanced iOS</button> */}
          </div>
          <div
            class="pricing-card "
            // featured onclick="selectCard(this)
          >
            <h3>Non-Intrusive Expansion</h3>
            <p class="price">
              Non-intrusive expansion enables separate deployments, supporting
              business growth and swift adaptation to evolving requirements and
              market demands.
            </p>
          </div>
          <div class="pricing-card-2" onclick="selectCard(this)">
            <h3>Seamless Integration and Deployment</h3>
            <p class="price2">
              Seamless integration and deployment allow for daily updates and
              smooth implementation of services, ensuring continuous
              functionality and efficiency.
            </p>
          </div>
        </div>
      </div>

      <div className="deft-subdiv-microservices" data-aos="zoom-in-up"></div>

      <div class="deft-microservices-main">
        <div class="deft-microservices-body">
          <h1>Features</h1>
          <ul class="deft-microservices-ul">
            <li style={{ backgroundColor: "#1286A8" }}>
              {/* <div class="icon"><i class="fa-brands fa-html5"></i></div> */}
              <div class="title">Scalability</div>
              <div class="descr">
                {" "}
                Each microservice can be scaled independently, allowing for more
                efficient resource usage and performance optimization based on
                demand.
              </div>
            </li>
            <li style={{ backgroundColor: "#D2B53B" }}>
              {/* <div class="icon"><i class="fa-brands fa-css3"></i></div> */}
              <div class="title">Resilience</div>
              <div class="descr">
                {" "}
                Microservices are designed to operate independently, so if one
                service fails, others continue to function, minimizing system
                downtime.
              </div>
            </li>
            <li style={{ backgroundColor: "#0B374D" }}>
              <div class="title">Modularity</div>
              <div class="descr">
                Microservices break down complex applications into smaller,
                independent services, each focused on a specific business
                function.
              </div>
            </li>
            <li style={{ backgroundColor: "#DA611E" }}>
              {/* <div class="icon"><i class="fa-brands fa-js"></i></div> */}
              <div class="title">Flexibility in Technology</div>
              <div class="descr">
                {" "}
                Different microservices can be developed using various
                programming languages and technologies, allowing teams to use
                the best tools for each task.
              </div>
            </li>
            <li style={{ backgroundColor: "#AC2A1A" }}>
              {/* <div class="icon"><i class="fa-brands fa-github"></i></div> */}
              <div class="title">Faster Development and Deployment</div>
              <div class="descr">
                By enabling smaller, autonomous teams to work on different
                services, microservices support rapid development cycles and
                faster deployment of updates.
              </div>
            </li>
          </ul>

          <ul class="deft-microservices-ul">
            <li style={{ backgroundColor: "#D2B53B" }}>
              {/* <div class="icon"><i class="fa-brands fa-css3"></i></div> */}
              <div class="title">Better Fault Isolation</div>
              <div class="descr">
                {" "}
                Isolated services mean that faults in one area do not propagate
                to the rest of the system, improving overall system stability.
              </div>
            </li>
            <li style={{ backgroundColor: "#1286A8" }}>
              {/* <div class="icon"><i class="fa-brands fa-js"></i></div> */}
              <div class="title">API-Driven</div>
              <div class="descr">
                {" "}
                Microservices communicate with each other through well-defined
                APIs, making integration easier and ensuring loose coupling
                between services .
              </div>
            </li>

            <li style={{ backgroundColor: "#DA611E" }}>
              <div class="title">Improved Maintainability</div>
              <div class="descr">
                Microservices make it easier to manage and update applications
                because changes can be made to individual services without
                impacting the whole system.
              </div>{" "}
            </li>
            <li style={{ backgroundColor: "#AC2A1A" }}>
              <div class="title">Continuous Delivery</div>
              <div class="descr">
                {" "}
                With isolated services, microservices enable continuous
                integration and delivery, ensuring a smoother and more frequent
                release of new features.
              </div>
            </li>

            <li style={{ backgroundColor: "#0B374D" }}>
              {/* <div class="icon"><i class="fa-brands fa-github"></i></div> */}
              <div class="title">Optimized for Cloud Environments</div>
              <div class="descr">
                Microservices are ideal for cloud-native architectures,
                leveraging containerization and orchestration tools like
                Kubernetes for efficient deployment and management.
              </div>
            </li>
          </ul>
        </div>
      </div>
      <div style={{ height: "20vh" }}></div>
    </div>
  );
}

export default Microservices;
