import React from "react";
import { Container, Row, Col } from "react-bootstrap";

function Fintech() {
  return (
    <div>
      <div className="deft-desktop-supplychain"></div>
      <div class="twelve">
        <h1 class="it-consulting-heading" data-aos="zoom-in-down">
          FINTECH
        </h1>
      </div>
      <br />
      
      <Container>
        <Row className="justify-content-center">
          <Col xs={12} md={8}>
            <div
              style={{
                color: "#777777",
                lineHeight: "24px",
                padding: "20px",
              }}
            >
              <p className="text-center">
                We lead the way in financial innovation by providing advanced
                fintech solutions that help businesses optimize operations,
                improve customer experiences, and foster growth. Our IT
                consulting services are tailored to assist financial
                institutions, startups, and enterprises in leveraging technology
                to address complex challenges across areas such as payments,
                lending, blockchain, and digital banking.
              </p>
            </div>
          </Col>
        </Row>
      </Container>
      <br />
      {/* ==========first div============= */}
      <div class="section2-deft-demo">
        <div class="text_section2-deft-demo">
          <h1>Incentives</h1>
          <p>
            Unlock the power of Fintech combined with strategic incentives to
            drive growth, loyalty, and financial empowerment. Our innovative
            fintech platform offers seamless financial services while
            incorporating tailored incentives that motivate and engage users.
            Whether you're looking to reward customer behavior, encourage
            savings, or boost transaction volumes, our fintech solutions are
            designed to deliver real value.With smart incentive structures,
            real-time tracking, and AI-powered analytics, businesses can
            optimize customer experiences, increase retention, and fuel
            profitability.
          </p>
        </div>
        <div class="image_section2-deft-demo">
          <img src="../../Assets/fintechimg.jpg" alt="Flexible Shoes" />
        </div>
      </div>
      {/* ==========seconnd div========== */}
      <div class="section2-deft-demo">
        <div class="image_section2-deft-demo">
          <img src="../../Assets/fintechimg2.jpg" alt="Flexible Shoes" />
        </div>
        <div class="text_section2-deft-demo">
          <h1>Sales</h1>
          <p>
            Our Fintech solutions enable your business to stay competitive and
            agile. Harness the potential of Fintech to supercharge your sales
            and drive revenue growth. Our cutting-edge financial technology
            seamlessly integrates with your sales processes, providing secure
            transactions, real-time payment processing, and actionable insights
            to enhance performance. By simplifying payments and improving the
            customer experience, our platform streamlines sales cycles, reduces
            friction, and boosts conversion rates. With features like predictive
            analytics, fraud protection, and automated financial workflows.
            <br />
            <br />
            At Deft Lab, we combine IT consulting expertise with Fintech
            innovations to deliver tailored solutions that enhance sales
            effectiveness. Our services help you set achievable targets, track
            progress seamlessly, and reward high-performing team members with
            automated, transparent incentive systems.
          </p>
        </div>
      </div>
      {/* ================third div================ */}
      <div class="section2-deft-demo">
        <div class="text_section2-deft-demo">
          <h1>Sales Target</h1>
          <p>
            Our IT consulting services empower businesses with the tools and
            strategies needed to streamline processes, improve decision-making,
            and drive revenue growth.
          </p>

          <Container style={{marginLeft:"-20px"}}>
            <strong style={{ fontWeight: "bold" }}>
              We offer tailored fintech solutions to help meet your sales goals:
            </strong>
            <ul>
              <li style={{ color: "#777777" }}>
                <strong>Data-Driven Insights:</strong> Leverage advanced
                analytics to identify opportunities, predict customer behavior,
                and optimize sales strategies.
              </li>
              <li style={{ color: "#777777" }}>
                <strong>Personalized Customer Experiences:</strong> Implement
                AI-driven tools to deliver tailored financial solutions that
                boost customer satisfaction and loyalty.
              </li>
              <li style={{ color: "#777777" }}>
                <strong>Sales Automation:</strong> Streamline sales workflows
                with automated tools that reduce manual tasks and increase
                efficiency.
              </li>
              <li style={{ color: "#777777" }}>
                <strong>Risk Management:</strong> Use predictive models to
                minimize risks and make informed financial decisions that
                support growth.
              </li>
            </ul>
          </Container>
        </div>
        <div class="image_section2-deft-demo">
          <img src="../../Assets/fintechimg3.png" alt="Flexible Shoes" />
        </div>
      </div>
      {/* ==========fourth div========== */}
      <div class="section2-deft-demo">
        <div class="image_section2-deft-demo">
          <img src="../../Assets/fintechimg4.jpeg" alt="Flexible Shoes" />
        </div>
        <div class="text_section2-deft-demo">
          <h1>Performance</h1>
          <p>
            In today’s rapidly evolving financial landscape, performance-driven
            fintech solutions are essential for businesses to thrive. Our IT
            consulting firm specializes in developing and implementing
            cutting-edge fintech solutions tailored to optimize your operations
            and drive growth.
            <br />
            <br />
            With our expertise, you can optimize your performance by automating
            routine tasks, reducing operational costs, and ensuring regulatory
            compliance. We help you leverage data-driven insights to make
            informed decisions, stay ahead of market trends, and enhance
            customer satisfaction. Partner with us to transform your fintech
            operations into a performance powerhouse, enabling you to innovate,
            scale, and achieve sustainable success in a competitive environment.
          </p>
        </div>
      </div>
      <br /><br /><br />
    </div>
  );
}

export default Fintech;
